<template>
    <div v-if="getCheckoutPackage" class="fields">
        <h3>{{ $t("checkout.youtubeUrlField") }}</h3>
        <div class="fields-row">
            <input type="text" :class="{ error: isYoutubeUrlTouched && (!isYoutubelLinkValid || !youtubeUrl) }" v-model="youtubeUrl" @blur="isYoutubeUrlTouched = true" :placeholder="$t('checkout.youtubeurlFieldPalysholder')">
        </div>
        <div class="error-message">
            <span v-if="isYoutubeUrlTouched && (!isYoutubelLinkValid || !youtubeUrl)">{{ $t("checkout.youtubeUrlFieldError") }}</span>
        </div>
        <div class="fields-row">
            <div class="fields-cel-2">
                <div v-if="youtubeVideo.youTubeVideoUrl" class="preview-wrapper">
                    <div class="preview-image" :style="'background-image: url('+ youtubeVideo.youTubeVideoUrl +')'"></div>
                    <div class="image-background"></div>
                </div>
                <div v-if="youtubeVideo.youTubeVideoUrl" class="preview-info">
                    <div class="preview-info-top">
                        <p>
                            <span class="eye-icon"></span> {{ youtubeVideo.startViewsPreview }} <span class="progress-color"> + {{ getCheckoutPackage.views_amount }} {{ $t("checkout.viewsTitle") }} </span>
                        </p>
                    </div>
                    <h4 class="youtube-title">{{ youtubeVideo.youtubeVideoTitle }}</h4>
                </div>
                <p class="youtube-text" v-if="youtubeVideo.youTubeVideoUrl">{{ youtubeVideo.youtubeVideoDescription | stringLength(130) }}</p>
            </div>
        </div>

        <h3 v-if="getCheckoutPackage.has_promo_codes">{{$t("checkout.promoCode")}}</h3>
        <div v-if="getCheckoutPackage.has_promo_codes" class="fields-row">
            <input type="text"  @blur="$event.target.value ? fetchCheckoutPromoCodeValidationResult($event.target.value) : clearCheckoutPromoCodeValidationResult()">
        </div>
        <div v-if="getCheckoutPackage.has_promo_codes && getCheckoutPromoCodeValidationResult && !getCheckoutPromoCodeValidationResult.is_valid" class="error-message">
            <span v-if="getCheckoutPromoCodeValidationResult.is_code_invalid">{{$t("checkout.promoCodeError")}}</span>
            <span v-if="getCheckoutPromoCodeValidationResult.is_code_paused">{{$t("checkout.promoCodeError")}}</span>
            <span v-if="getCheckoutPromoCodeValidationResult.is_code_not_started">{{$t("checkout.promoCodeError")}}</span>
            <span v-if="getCheckoutPromoCodeValidationResult.is_code_expiered">{{$t("checkout.promoCodeError")}}</span>
        </div>
        
        <h3>{{ $t("checkout.contactInformation") }}</h3>
        <div class="fields-row">
            <div class="fields-cel-2">
                <input type="text" :class="{ error: isEmailTouched && (!email || !isEmailValid) }" v-model="email" @blur="isEmailTouched = true" :placeholder="$t('contactUs.form.emailPlaceholder')">
                <div class="error-message">
                    <span v-if="isEmailTouched && !email">{{
                        $t("contactUs.form.required")
                    }}</span>
                    <span v-if="isEmailTouched && email && !isEmailValid">{{
                        $t("contactUs.form.emailInvalid")
                    }}</span>
                </div>
            </div>
        </div>
        <div class="fields-row">
            <div class="fields-cel">
                <input v-model="firstName" :class="{ error: isFirstNameTouched && !firstName }" type="text" @blur="isFirstNameTouched = true" :placeholder="$t('contactUs.form.firstName')">
                <div class="error-message">
                    <span v-if="isFirstNameTouched && !firstName">{{
                        $t("contactUs.form.required")
                    }}</span>
                </div>
            </div>
            <div class="fields-cel">
                <input v-model="lastName" :class="{ error: isLastNameTouched && !lastName }" type="text" @blur="isLastNameTouched = true" :placeholder="$t('contactUs.form.lastName')">
                    <div class="error-message">
                    <span v-if="isLastNameTouched && !lastName">{{
                        $t("contactUs.form.required")
                    }}</span>
                </div>
            </div>
        </div>
        <div class="fields-row">
            <div class="fields-cel">
                <div
                    class="desctop-select"
                    :class="{
                        droped: isCountrySelectShown,
                        error: isCountriesTouched && country == null
                    }"
                >
                    <span
                        v-on:click="showCloseCountriesSelect"
                        v-if="country == null"
                    >{{ $t("contactUs.form.country") }}</span>
                    <template v-for="(item, index) in countries">
                    <span
                        v-on:click="showCloseCountriesSelect"
                        v-if="country == index"
                        :key="index"
                        >{{ item[$i18n.locale] }}</span
                    >
                    </template>
                    <ul>
                    <template v-for="(item, index) in countries">
                        <li v-on:click="country = index" :key="index">
                        {{ item[$i18n.locale] }}
                        </li>
                    </template>
                    </ul>
                </div>
                <div class="mobile-select">
                    <select
                    @change="country = $event.target.value"
                    :class="{ error: isCountriesTouched && !country }"
                    >
                    <option selected disabled value="null">{{ $t("contactUs.form.country") }}</option>
                    <template v-for="(item, index) in countries">
                        <option :value="index" :key="index">{{ item[$i18n.locale] }}</option>
                    </template>
                    </select>
                </div>
                <div class="error-message">
                    <span v-if="isCountriesTouched && country == null">{{
                        $t("contactUs.form.required")
                    }}</span>
                </div>
            </div>
            <div class="fields-cel">
                <input type="text" v-model="city" :class="{ error: isCityTouched && !city }" :placeholder="$t('checkout.cityPlaysholde')" @blur="isCityTouched = true">
                <div class="error-message">
                    <span v-if="isCityTouched && !city">{{
                        $t("contactUs.form.required")
                    }}</span>
                </div>
            </div>
        </div>
        <div class="fields-row checkbox-row">
            <input type="text" v-model="phone" :placeholder="$t('checkout.phonePlaysholder')">
        </div>
        <div class="fields-row checkbox-row">
            <div class="fields-cel-2">
                <label class="checkbox-label"  @click="isConfirmCorrectYoutubeLinkTouched = true">
                    <checkbox v-model="confirmCorrectYoutubeLink" :invalid="isConfirmCorrectYoutubeLinkTouched && !confirmCorrectYoutubeLink" />
                    <span class="checkbox-label-text">{{ $t("checkout.confirmMessage1") }}</span>
                </label>
            </div>
        </div>
        <div class="fields-row checkbox-row">
            <div class="fields-cel-2">
                <label class="checkbox-label" @click="isconfirmOutherPromotionTouched = true">
                    <checkbox v-model="confirmOutherPromotion" :invalid="isconfirmOutherPromotionTouched && !confirmOutherPromotion" />
                    <span class="checkbox-label-text">{{ $t("checkout.confirmMessage2") }}</span>
                </label>
            </div>
        </div>
        <div class="fields-row checkbox-row">
            <div class="fields-cel-2">
                <label class="checkbox-label" @click="isConfirmUpdatesTouched = true">
                    <checkbox v-model="confirmUpdates" :invalid="isConfirmUpdatesTouched && !confirmUpdates" />
                    <span class="checkbox-label-text">{{ $t("checkout.confirmMessage3") }}</span>
                </label>
            </div>
        </div>
        <div class="save-form-btn" :class="{'disabled': !isFormValid || formBlocked}" v-if="!formSaved && !preConfirmFormProcessing" v-on:click="saveOrder()">
            <div class="save-form-btn-inner">{{ $t("checkout.buttonText") }}</div>
        </div>
        <div v-if="formBlocked" class="blocked-label">{{ $t("checkout.blockedMessage") }}</div>
        <div v-if="preConfirmFormProcessing" class="spiner-wrapper">
            <b-spinner class="d-flex justify-content-center mb-3" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <h3 v-if="formSaved && !preConfirmFormProcessing">{{ $t("checkout.paymentMethod") }}</h3>
        <div v-if="formSaved" id="pay-pal" class="paypal-button"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import COUNTRIES from "./../../resorces/countries.json";
import checkbox from './checkbox';
import { youtubeVideoPreview } from './../../mixins/youtube-v-preview.mixin';
import { cropText } from './../../mixins/crop-text.mixin';
const emailCheckRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
    mixins: [youtubeVideoPreview, cropText],

    components: {
        checkbox,
    },
    data() {
        return {
            formBlocked: true,
            promoCode: null,
            pakages: {},
            months: {
                "01": "Jan.",
                "02": "Feb.",
                "03": "Mar.",
                "04": "Apr.",
                "05": "May",
                "06": "Jun.",
                "07": "Jul.",
                "08": "Aug.",
                "09": "Sept.",
                "10": "Oct.",
                "11": "Nov.",
                "12": "Dec."
            },
            formSaved: false,
            external_id: null,
            youtubeUrl: null,
            packagePrice: 0,
            packageTitle: null,       
            packageDescription: null,   
            packageViews: 0,      

            email: null,
            phone: null,
            firstName: null,
            lastName: null,
            city: null,
            country: null,

            confirmCorrectYoutubeLink: false,
            confirmUpdates: false,
            confirmOutherPromotion: false,

            isYoutubeUrlTouched: false,
            isEmailTouched: false,
            isFirstNameTouched: false,
            isLastNameTouched: false,
            isCityTouched: false,
            isCountriesTouched: false,
            isConfirmCorrectYoutubeLinkTouched: false,
            isConfirmUpdatesTouched: false,
            isconfirmOutherPromotionTouched: false,
            
            countries: null,
            isCountrySelectShown: false,
            
            isFormDisabled: false,
            paypalAction: null,
            preConfirmFormProcessing: false,
        }
    },
    methods: {
        ...mapActions([
            'fetchCheckoutPromoCodeValidationResult'
        ]),
        ...mapMutations([
            'clearCheckoutPromoCodeValidationResult'
        ]),
        async saveOrder() {
            await this.touchAll();
            
            if(!this.isFormValid || this.formBlocked) {
                return;
            }

            this.preConfirmFormProcessing = true;

            this.$http.post('order/init', {
                'email': this.email,
                'phone': this.phone,
                'first_name': this.firstName,
                'last_name': this.lastName,
                'country': this.countries[this.country]['en'],
                'city': this.city,          
                'youtube_video_url': this.youtubeUrl,
                'package_slug': this.getCheckoutPackage.slug,
                'promo_code': this.getCheckoutPromoCodeValidationResult ? this.getCheckoutPromoCodeValidationResult.promo_code : null
            }).then(resp => {
                this.isFormDisabled = true;
                this.formSaved = true;
                const stripe = window.Stripe(resp.data.public_key);
                return stripe.redirectToCheckout({ sessionId: resp.data.session_id });
            }, () => {
                this.$router.push({name: 'checkout-error'});
            });
        },
        showCloseCountriesSelect(event) {
            if (this.isFormDisabled) {
                return;
            }

            event.stopPropagation();

            this.isCountrySelectShown = !this.isCountrySelectShown;
        },
        async touchAll() {
            this.isYoutubeUrlTouched = true;
            this.isEmailTouched = true;
            this.isFirstNameTouched = true;
            this.isLastNameTouched = true;
            this.isCityTouched = true;
            this.isCountriesTouched = true;
            this.isConfirmCorrectYoutubeLinkTouched = true;
            this.isConfirmUpdatesTouched = true;
            this.isconfirmOutherPromotionTouched = true;

            if(this.getCheckoutPromoCodeValidationResult != null && this.getCheckoutPromoCodeValidationResult.is_valid) {
                await this.fetchCheckoutPromoCodeValidationResult(this.getCheckoutPromoCodeValidationResult.promo_code);
            }
        }
    },
    mounted() {
        const usNode = COUNTRIES.find(c => c.code == 'US');
        const jaNode = COUNTRIES.find(c => c.code == 'JP');

        const _countries = COUNTRIES.filter(x => x.code != 'US' && x.code != 'JP'); 

        this.countries = _countries.sort(function(a, b) {
            if (a.en > b.en) {
                return 1;
            }

            if (a.en < b.en) {
                return -1;
            }

            return 0;
        });

        this.countries.unshift(jaNode);
        this.countries.unshift(usNode);

        document.addEventListener("click", () => {
            if (this.isCountrySelectShown) {
                this.isCountrySelectShown = false;
                this.isCountriesTouched = true;
            }
        });
    },
    computed: {
        ...mapGetters([
            'getCheckoutPackage',
            'getCheckoutPromoCodeValidationResult'
        ]),
        isEmailValid() {
           return emailCheckRegex.test(this.email);
        },
        isYoutubelLinkValid() {
             return this.checkIsValidYouTubeLink(this.youtubeUrl);
        },
        isFormValid() {
            const isValid = this.isEmailValid && 
                            this.email && 
                            this.isYoutubelLinkValid &&
                            this.youtubeUrl && 
                            this.firstName && 
                            this.lastName && 
                            this.city && 
                            this.country != null && 
                            this.confirmCorrectYoutubeLink &&
                            this.confirmUpdates &&
                            this.confirmOutherPromotion &&
                            (this.getCheckoutPromoCodeValidationResult == null || this.getCheckoutPromoCodeValidationResult.is_valid)

            return isValid;
        }
    },
    watch: {
        isFormValid: function (isValid) {
            if(!this.formSaved) return;

             if(isValid) {
                this.paypalAction.enable();
            } else {
                this.paypalAction.disable();
            }
        },
        youtubeUrl: function(value) {
            this.getYouTubeVideoInfoFromUrl(value);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

.spiner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.error-message {
  span {
    color: #f36;
    font-family: $family-light;
    font-size: 18px;
  }
  .success-note {
    color: green;
    font-family: $family-light;
  }
}

.blocked-label {
    color: #f36;
    font-family: $family-light;
    font-size: 18px;
    text-align: center;
    padding: 10px 10px;
}

.checkout-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px 40px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
}

.logo {
  position: relative;
  top: -3px;
  display: block;
  background-image: url(/images/logo/D&D_h_logo_full_black.png);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 40px;
  width: 94px;
}

.checkout-wrapper {
    background: #F5F6FC;
    min-height: 100vh;

    h2 {
        text-align: center;
        padding: 100px 0 20px;
        @include font-size(40px);
        font-family: $family-bold;
        font-weight: 700;
    }

    h3 {
        padding: 20px 0 0;
        font-weight: 700;
        position: relative;
        bottom: -10px;
    }
}

.checkout-sub-title {
    padding: 0px 0 80px;
    text-align: center;
    font-family: $family-light;
    @include font-size(25px);
}

.checkout-inner {
    width: 80%;
    margin:auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10vh;
}

.fields {
    width: 49.5%;
}

.cart {
    width: 49.5%;
    display: flex;
    justify-content: center;
}

.fields input {
    display: block;
    width: 100%;
    outline: none;
    padding: 5px;
    border: 1px solid #d0d5d8;
    border-radius: 2px;
    resize: none;
    padding: 18px 15px;
    @include font-size(18px);
    color: #8a959e;
    background: #fff;
    font-family: $family-light;
    -webkit-appearance: none;
    appearance: none;
}
.fields-row {
    display: flex;
    padding: 10px 0;
    position: relative;
    z-index: 998;

    input, select {
        display: block;
        width: 100%;
        outline: none;
        padding: 5px;
        border: 1px solid #d0d5d8;
        border-radius: 2px;
        resize: none;
        padding: 18px 15px;
        @include font-size(18px);
        color: #8a959e;
        background: #fff;
        font-family: $family-light;
        -webkit-appearance: none;
        appearance: none;

        &::placeholder {
            color: #8a959e;
        }

        &.error {
            border-color: #f36;
        }
    }
}

.fields-cel {
    z-index: 2;
    width: 49.5%;
    float: left;

    &:first-child {
        margin-right: 1%;
    }
}

.fields-cel-2 {
    width: 100%;
}

.desctop-select {
  position: relative;

  &.error span {
    border-color: #f36;
  }

  span {
    font-family: $family-light;
    position: relative;
    display: block;
    border: 1px solid #d0d5d8;
    border-radius: 2px;
    padding: 18px 15px;
    cursor: pointer;
    color: #8a959e;
    background: #fff;
    @include font-size(18px);

    &::after {
      content: "";
      position: absolute;
      margin-top: -0.6em;
      border: solid transparent;
      border-bottom-color: #a4abb1;
      border-width: 0.3em;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      margin-top: 0.2em;
      border: solid transparent;
      border-width: 0.3em;
      border-top-color: #a4abb1;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }
  }

  ul {
    display: none;
    position: absolute;
    background: #fff;
    width: 100%;
    border: 1px solid #d0d5d8;
    border-top: 0px;
    z-index: 2;

    li {
      cursor: pointer;
      color: #8a959e;
      @include font-size(18px);
      padding: 18px 15px;
      font-family: $family-light;

      &:hover {
        background: #d0d5d8;
      }
    }
  }

  &.droped {
    ul {
      z-index: 9;
      display: block;
      max-height: 40vh;
      overflow-y: scroll;
    }
  }
}

.preview-wrapper {
    display: flex;
    position: relative;
    overflow: hidden;
    height: 220px;
    margin: 10px 0;

    .preview-image {
        position: relative;
        background-position: center;
        background-size: cover;
        width: 50%;
        border-radius: 5px;
        margin-right: 5%;

        &::after {
            content: '';
            position: absolute;
            top: 0%;
            right: 0%;
            bottom: 0%;
            left: 0%;
            background: rgba(0, 0, 0, .7);
            border-radius: 5px;
        }
    }


    .dot-title-video {
        height: 4px;
        width:4px;
        border-radius: 50%;
        background: #909090;
        margin: 10px 7px 0;
        font-family: Helvetica, sans-serif;
    }


    h4 {
        font-family: $family-bold;
        font-size: 22px;
    }

    p {
        font-family: $family-light;
        font-size: 18px;
    }
}

.preview-info-top {
    display: flex;
    position: relative;
    margin-bottom: 5px;


    p {
        font-family: $family-light;
        font-weight: 500;
    }
}

.youtube-title {
    font-weight: 700;
    padding-bottom: 10px;
}

.youtube-text {
    font-family: $family-light;
}

@media screen and (max-width: 1250px) {
    .preview-wrapper {
        display: flex;
        flex-direction: column;
        height: 40vh;

        & + p {
            width: 90%; 
        }
    }
    .preview-wrapper .preview-image {
        height: 40vh;
        border-radius: 5px;
        width: 90%;
        &::after {
            border-radius: 5px;
        }
    }

    .preview-info {
        width: 90%;
        padding-top: 20px;

        .preview-info-top {
            margin-bottom: 15px;
        }

        h4 {
            font-weight: 700;
        }
    }
    
}

.checkbox-label {
    position: relative;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.checkbox-label-text {
    padding-left: 5px;
    font-family: $family-light;

    a {
        font-family: $family-light;
        color: #212529;
        text-decoration: underline;
    }
}

.checkbox-wrapper {
    top: 4px;
}

.paypal-button {
    margin-top: 10px;
}

.checkbox-row {
    z-index: 888;
}

.mobile-select {
    position: relative;
    display: none;
    cursor: pointer;
    color: #8a959e;
    @include font-size(18px);

    &::after {
      content: "";
      position: absolute;
      margin-top: -0.6em;
      border: solid transparent;
      border-bottom-color: #a4abb1;
      border-width: 0.3em;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      margin-top: 0.2em;
      border: solid transparent;
      border-width: 0.3em;
      border-top-color: #a4abb1;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }
}

.eye-icon {
    font-size: 19px;
    display: inline-block;
    background-image: url('/images/icons/eye.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 17px;
    width: 25px;
}

.progress-color {
    color: green;
    font-weight: 500;
    font-family: $family-light;
}

@media (max-width: 1024px) {
    .checkout-inner {
        display: flex;
        flex-direction: column-reverse;
    }

    .cart {
        width: 100%;
        padding-bottom: 10vh;
    }

    .fields {
        width: 100%;
    }

    .desctop-select {
        display: none;
    }

    .mobile-select {
        display: block;
    }
    .preview-wrapper {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 576px) {

    .checkout-inner {
        width: 90%;
    }

    .checkout-nav {
        padding: 20px 25px;
    }

    .fields-row {
        flex-wrap: wrap;
    }

    .ja.checkout-wrapper h2 {
        padding: 100px 25px 20px;
        @include font-size(30px);
    }

    .ja .checkout-sub-title {
        padding: 0px 20px 80px;
        text-align: center;
        font-family: $family-light;
        @include font-size(19px);
    }

    .fields-row  input, .fields-row select {
        padding: 12px 15px;
        @include font-size(14.5px);
        font-size: 3.5vw;
    }
    
    .fields-cel {
        z-index: 2;
        width: 100%;
        float: left;
        &:first-child {
            margin-right: 0%;
            margin-bottom: 20px;
        }
    }

    .preview-wrapper {
        position: relative;
        overflow: hidden;
        height: auto;
        margin: 10px 0;

        & + p {
            overflow: hidden;
        } 

        .preview-image {
            position: relative;
            height: 200px;
            background-position: center;
            background-size: cover;
            width: 100%;
            border-radius: 5px;

            &::after {
                content: '';
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom: 0;
                background: rgba(0, 0, 0, .7);
                border-radius: 5px;
            }
        }

        h4 {
            position: static;
            padding-left: 0;
            padding-top: 20px;
            font-family: $family-bold;
            font-size: 22px;
        }

        p {
            position: static;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 0;
            font-family: $family-light;
            font-size: 18px;
        }
    }

    .preview-info {
        width: 100%;
        padding-top: 0;

        h4 {
            padding:0;
        }
    }

    .preview-info-top p{
        padding: 10px 0;
    }
}

.save-form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $gold;
    font-family: $family-bold;
    font-weight: 700;
    
    .save-form-btn-inner {
        text-align: center;
        width: 100%;
        border: 2px solid $gold;
        padding: 20px 0;
        @include font-size(18px);
        @include transition(all 100ms ease);
        
        
        &:hover {
            @include transition(all 100ms ease);
            border: 4px solid $gold;
            padding: 18px 0;
        }
    }

    &.disabled .save-form-btn-inner{
        color: #ccc;
        border: 2px solid #ccc;
    }

    &.disabled .save-form-btn-inner:hover {
        @include transition(all 100ms ease);
        border: 2px solid #ccc;
        padding: 20px 0;
    }

}
</style>