<template>
    <div class="checkbox-wrapper" :class="{'invalid': invalid}">
        <input type="checkbox" @change="$emit('input', $event.target.checked)" />
        <span></span>
    </div>
</template>
<script>
export default {
    props: {
        invalid: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
:root {
  .checkbox-wrapper {
    position: relative;
    height: 20px;
  }
  span {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 5px;
    transition: all linear 0.3s;
    border: 1.5px solid #68CA66;
    &:after {
      content: "";
      position: absolute;
      top: 4.3px;
      left: 8px;
      height: 9px;
      width: 4px;
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
      visibility: hidden;
    }
  }

  .invalid span {
    border: 1.5px solid red;
  }

  input {
    display: none;
    &:checked ~ span {
      background: #68CA66;
      &:after {
        visibility: visible;
      }
    }
  }
}
</style>